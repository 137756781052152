<!-- =========================================================================================
  File Name: View.vue
  Description: Hotel View page
  ----------------------------------------------------------------------------------------
  Item Name: Booking manager admin
    Author: HopNepal
    Author URL: https://www.hopnepal.com
========================================================================================== -->

<template>
  <div id="page-user-view">

    <vs-alert color="danger" title="Hotel Not Found" :active.sync="hotel_not_found">
      <span>Hotel record with id: {{ $route.params.hotelId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'hotels'}" class="text-inherit underline">All Hotels</router-link>
      </span>
    </vs-alert>

    <div id="user-data">

      <vx-card title="Hotel Details" class="mb-base">
        <!-- Avatar -->
        <div class="vx-row">
          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col" v-if="geo.images">
            <div class="img-container mb-4">
              <img class="rounded w-full" :src="preview_image" />
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Hotel Name</td>
                <td>{{form.name}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Slug</td>
                <td>{{form.slug}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Website</td>
                <td>{{form.website}}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Vendor</td>
                <td>{{geo.user_id.label}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{form.email}}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button icon-pack="feather" icon="icon-edit" class="mr-4" :to="{name: 'hotels.edit', params: { hotelId: $route.params.hotelId }}">Edit</vs-button>
            <vs-button type="border" color="danger" icon-pack="feather" icon="icon-trash" @click="confirmDeleteRecord">Delete</vs-button>
          </div>

        </div>

      </vx-card>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Information" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Primary Phone</td>
                <td>{{form.phone_1}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Secondary Phone</td>
                <td>{{form.phone_2}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Primary Address</td>
                <td>{{form.address_1}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Secondary Address</td>
                <td>{{form.address_2}}</td>
              </tr>
              <tr v-if="geo.amenities">
                <td class="font-semibold">Amenities</td>
                <td> 
                    <code class="amenity-base" v-for="(am,index) in geo.amenities" :key="index">{{am.label}}</code>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Status</td>
                <td><code :class="{'success': form.status,  'danger': !form.status}">{{form.status}}</code></td>
              </tr>
              <tr>
                <td class="font-semibold">Sponsored</td>
                <td><code :class="{'success': form.is_sponsored,  'danger': !form.is_sponsored}">{{form.is_sponsored}}</code></td>
              </tr>
            </table>
          </vx-card>
        </div>

        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Additional Info" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Region</td>
                <td>{{geo.hotel_region_id.label}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Min Payment Percent</td>
                <td>{{form.min_payment_percent}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Price Per Person</td>
                <td>{{form.default_price}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Secondary Address</td>
                <td>{{form.address_2}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Full Payment</td>
                <td><code :class="{'success': form.is_full_payment,  'danger': !form.is_full_payment}">{{form.is_full_payment}}</code></td>
              </tr>
              <tr>
                <td class="font-semibold">Star Rating</td>
                <td>{{form.star_rating}}</td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>
      <vx-card title="Hotel Images">
        <p>You can add only one image as featured image</p>
        <vs-table :data="geo.images">
            <template slot="thead">
                <vs-th>Image</vs-th>
                <vs-th>Alt Text</vs-th>
                <vs-th>Featured Image</vs-th>
                <vs-th>Action</vs-th>
            </template>
            <template>
                <vs-tr :key="indextr" v-for="(tr, indextr) in geo.images">
                    <vs-td><img class="featured-images" :src="tr.url" /></vs-td>
                    {{tr.alt}}
                    <vs-td><vs-switch :value="tr.featured" @input="changeStatus($event, tr.id)" :disabled="tr.featured" /></vs-td>
                    <vs-td><vs-button type="border" color="danger" icon-pack="feather" icon="icon-trash" @click="confirmDeleteHotelImage(tr.id)"></vs-button></vs-td>
                </vs-tr>
            </template>
        </vs-table>
      </vx-card>
      
    <vx-card title="Hotel Rooms" class="mb-base">
        <!-- Avatar -->
        <div class="vx-row">
            <!-- /Information - Col 2 -->
            <div class="vx-col w-full flex" id="account-manage-buttons">
                <vs-button icon-pack="feather" icon="icon-plus" class="mr-4" :to="{name: 'hotels.rooms.create', params: { hotelId: $route.params.hotelId }}">Create</vs-button>
            </div>
            <div id="page-user-list">
                <div class="vx-card p-6">
                <!-- AgGrid Table -->
                    <ag-grid-vue
                    ref="agGridTable"
                    :components="components"
                    class="ag-theme-material w-100 my-4 ag-grid-table"
                    :columnDefs="columnRoomsDefs"
                    :defaultColDef="defaultRoomsColDef"
                    :rowData="rooms.data"
                    rowSelection="multiple"
                    colResizeDefault="shift"
                    :animateRows="true"
                    :floatingFilter="true"        
                    :suppressPaginationPanel="true"
                    :enableRtl="$vs.rtl">
                    </ag-grid-vue>
                </div>
            </div>
        </div>
    </vx-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AgGridVue } from "ag-grid-vue"
import vSelect from 'vue-select'
import {destroyImage, makeFeaturedImage} from '../../../services/image';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererActions from "../rooms/page/cell-render/CellRendererActions.vue"
import CellRendererBoolean from "../rooms/page/cell-render/CellRendererBoolean.vue"
import loaderMixin from '../../../mixins/loaderMixin';

export default {
    mixins: [loaderMixin],
    components: {
        AgGridVue,
        vSelect,
        CellRendererActions,
        CellRendererBoolean
    },
    computed: {
        ...mapGetters({
            form: 'hotel/getFormObj',
            geo: 'hotel/getGeo',
            preview_image: 'hotel/previewImage',
            rooms: 'hotel/getFromHotelRooms',
        }),
        getHotelId() {
            return this.$route.params.hotelId;
        },
    },
    created() {
        this.openLoading();
        this.fetchAndSetHotelById(this.getHotelId)
            .then((res) => this.closeLoading()).catch((r) => this.closeLoading());
    },
    methods: {
        ...mapActions('hotel', [
            'deleteHotelAction',
            'fetchAndSetHotelById'
        ]),
        confirmDeleteRecord() {
          this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `Confirm Delete`,
            text: `You are about to delete record with id: "${this.getHotelId}"`,
            accept: this.deleteRecord,
            acceptText: "Delete"
          })
        },
        deleteRecord() {
          this.openLoading();
          this.deleteHotelAction(this.getHotelId)
              .then(() => this.deletedSuccessfully()).catch((error) => this.closeLoading());
        },
        deletedSuccessfully() {
          this.closeLoading();
          this.$vs.notify({
            color: 'success',
            title: 'Hotel Deleted',
            position: 'top-right',
            text: 'The selected hotel was successfully deleted'
          });
          this.$router.push("/hotels");
        },

      confirmDeleteHotelImage($id){
          this.imageId = $id;
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Confirm Delete`,
          text: `You are about to delete image with id: "${$id}"`,
          accept: this.deleteHotelImage,
          acceptText: "Delete"
        });
      },
      deleteHotelImage(){
        destroyImage(this.imageId).then((response) => {
            if(response.data.code === 204){
              this.openLoading();
              this.fetchAndSetHotelById(this.getHotelId)
                  .then((res) => this.closeLoading()).catch((r) => this.closeLoading());
                this.$vs.notify({ 
                    text: 'Hotel Images Deleted successfully.',
                    title: 'Hotel Images Deleted',
                    color: 'success',
                    position: 'top-right'
                });
            }
        });
      },
      
      changeStatus($event, imageId){
        this.openLoading();
        makeFeaturedImage({id: imageId, status: $event})
          .then(response => {
            if(response.status === 200) {
              this.fetchAndSetHotelById(this.getHotelId)
            .then((res) => this.closeLoading()).catch((r) => this.closeLoading());
              this.$vs.notify({ 
                  title: 'Hotel Images',
                  text: 'Hotel Images selected as featured image successfully.',
                  color: 'success',
                  position: 'top-right'
              });
            }
          }).catch((r) => this.closeLoading());
      }
    },
     data() {
        return {
            imageId: '',
            hotel_not_found: false,
            defaultRoomsColDef: {
                sortable: true,
                resizable: true,
                suppressMenu: true
            },
            columnRoomsDefs: [
                {
                headerName: 'ID',
                field: 'id',
                // width: 125,
                filter: true,
                },
                {
                headerName: 'Name',
                field: 'name',
                filter: true,
                width: 250,
                },
                {
                headerName: 'Status',
                field: 'status',
                // width: 150,
                cellRendererFramework: 'CellRendererBoolean'
                },
                {
                headerName: 'Actions',
                field: 'transactions',
                // width: 150,
                cellRendererFramework: 'CellRendererActions',
                },
            ],

            // Cell Renderer Components
            components: {
                CellRendererActions,
                CellRendererBoolean
            }
        }
    },
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}
.amenity-base{
    margin: 5px;
    background: #292d49;
    color: #fff;
}
.danger{
    background: #802424;
    color: #fff;
}

.success{
    background: #259972;
    color: #fff;
}

.featured-images{
  height: 50px;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }
  }
}


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

}

#page-user-list {
    width: 100%;
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

</style>
